<template>
    <div class="login bg-white">
        <el-tabs v-model="activeName">
            <el-tab-pane label="登录" name="login">
                <el-form ref="login" :model="loginForm" :rules="rulesLogin" label-width="0" class="login-form">
                    <el-form-item prop="user_name">
                        <el-input v-model="loginForm.user_name" placeholder="请输入您的用户名"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input v-model="loginForm.password" placeholder="请输入您的密码" type="password"
                                  show-password @keyup.enter.native="handleLogin"></el-input>
                    </el-form-item>
                    <el-button type="primary" class="login-btn" :loading="loginLoading" @click="handleLogin">登录
                    </el-button>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="注册" name="register">
                <el-form ref="register" :model="registerForm" :rules="rulesRegister" label-width="0" class="login-form">
                    <el-form-item prop="user_name">
                        <el-input v-model="registerForm.user_name" placeholder="请输入您的用户名" :maxlength="user_name_length"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input v-model="registerForm.password" placeholder="请设置一个密码" type="password"></el-input>
                    </el-form-item>
                    <el-form-item prop="again_password">
                        <el-input v-model="registerForm.again_password" placeholder="请再次输入密码"
                                  type="password" @keyup.enter.native="handleRegister"></el-input>
                    </el-form-item>
                    <el-form-item prop="user_mail">
                        <el-input v-model="registerForm.user_mail" placeholder="邮箱（可选，用于找回密码）"
                                  type="text" @keyup.enter.native="handleRegister"></el-input>
                    </el-form-item>
                    <el-button type="primary" class="login-btn" :loading="registerLoading" @click="handleRegister">注册
                    </el-button>
                </el-form>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {Tabs, TabPane, Form, FormItem,MessageBox} from 'element-ui';

    Vue.use(Tabs);
    Vue.use(TabPane);
    Vue.use(Form);
    Vue.use(FormItem);
    import {user} from '../api/api';
    import app from '../utils/app';

    export default {
        name: "Login",
        components: {},
        computed:{
            user_name_length(){
                return isNaN(Number(this.registerForm.user_name)) ? 8 : 11
            }
        },
        data() {
            const validatePass = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('请输入密码'));
                } else if (value.includes(' ')) {
                    callback(new Error('密码不能含有空格!'));
                } else {
                    callback();
                }
            };
            const validateName = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('请输入用户名'));
                } else if (value.includes(' ')) {
                    callback(new Error('用户名不能含有空格!'));
                } else {
                    callback();
                }
            };
            const validateAgainPass = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.registerForm.password) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            return {
                activeName: 'login', // tab切换
                loginForm: {
                    user_name: '',
                    password: ''
                }, // 登录的数据
                rulesLogin: {
                    user_name: [
                        {trigger: 'blur', validator: validateName}
                    ],
                    password: [
                        {trigger: 'blur', validator: validatePass}
                    ],
                },
                rulesRegister: {
                    user_name: [
                        {trigger: 'blur', validator: validateName}
                    ],
                    password: [
                        {trigger: 'blur', validator: validatePass}
                    ],
                    again_password: [
                        {trigger: 'blur', validator: validateAgainPass}
                    ],
                },
                registerForm: {
                    user_name: '',
                    password: '',
                    again_password: ''
                }, // 注册的数据
                loginLoading: false,
                registerLoading: false,
            }
        },
        methods: {
            // 点击登录
            handleLogin() {
                this.$refs['login'].validate(async (valid) => {
                    if (valid) {
                        this.loginLoading = true;
                        let params = {...this.loginForm};
                        params.password = app.md5(params.password);
                        const req = await app.ajax(user.login, params);
                        this.loginLoading = false;
                        if (req.code === 1) {
                            this.setUserInfo(req.data);
                            app.alert('登录成功', 'success');
                            this.$router.back();
                        } else {
                            app.alert(req.msg, 'error');
                        }
                    }
                });
            },
            // 点击注册
            handleRegister() {
                this.$refs['register'].validate(async (valid) => {
                    if (valid) {
                        this.registerLoading = true;
                        let params = {...this.registerForm};
                        delete params.again_password;
                        params.password = app.md5(params.password);
                        const req = await app.ajax(user.addUser, params);
                        this.registerLoading = false;
                        if (req.code === 1) {
                            MessageBox.confirm('注册成功，是否现在登录？', '', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'success'
                            }).then(async () => {
                                const loading = this.$loading({
                                    lock: true,
                                    text: '登录中...',
                                    spinner: 'el-icon-loading',
                                    background: 'rgba(0, 0, 0, 0.5)'
                                });
                                const req_login = await app.ajax(user.login, params);
                                if (req_login.code === 1) {
                                    this.setUserInfo(req_login.data);
                                    app.alert('登录成功', 'success');
                                    this.$router.back();
                                } else {
                                    app.alert(req_login.msg, 'error');
                                }
                                loading.close();
                            }).catch(() => {
                                this.registerForm = {
                                    user_name: '',
                                    password: '',
                                    again_password: ''
                                };
                            });
                        } else {
                            app.alert(req.msg, 'error');
                        }
                    }
                });
            },
            // 登录成功，保存用户数据及更新vuex中的数据
            setUserInfo(info) {
                this.$store.commit('setUserInfo', info || {});
            }
        },
        mounted() {
            // 添加背景图，在注销时移除
            document.getElementsByTagName("body")[0].className = "body-css";
        },
        beforeDestroy() {
            document.body.removeAttribute("class", "body-css");
        }
    }
</script>

<style lang="scss">
    .body-css {
        height: 100vh;
        background-size: cover;
        -webkit-background-size: cover;
        -o-background-size: cover;
        background-position: center 0;
        background-repeat: no-repeat;
        background-image: url("http://bug.duolaimeng.net/uploads/bg/bg.jpeg");
    }

    .login {
        width: 300px;
        padding: 10px 20px;
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 15;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.8);

        .login-form {
            padding: 10px 0;

            .login-btn {
                width: 100%;
                padding: 0;
                height: 40px;
                line-height: 40px;
            }
        }
    }
</style>